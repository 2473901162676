import React from "react";

const MediaModal = ({ setIsOpen, type, media }) => {
  return (
    <div
      onClick={() => setIsOpen(false)} // Close modal on backdrop click
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the backdrop
        className="bg-white rounded-lg flex flex-col justify-center items-center shadow-lg w-8/12 mx-4 p-6 relative"
        role="dialog"
        aria-modal="true">
        {/* Modal Header */}
        <div className="flex justify-end self-end items-center mb-4">
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-400 hover:text-gray-600">
            ✕
          </button>
        </div>

        <div className="w-8/12  self-center   p-2 flex items-center">
          {type === "video" ? (
            <video
              className="w-full aspect-video border "
              controls
              src={media}></video>
          ) : type === "image" ? (
            <img
              alt="qualifications"
              src={media}
              className="max-h-[600px] self-center mx-auto"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaModal;
