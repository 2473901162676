import React from "react";

const ConfirmationModal = ({ setIsOpen, type, id, handleApproval }) => {
  return (
    <div
      onClick={() => setIsOpen(false)} // Close modal on backdrop click
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the backdrop
        className="bg-white rounded-lg flex flex-col justify-center items-center shadow-lg w-5/12 mx-4 p-6 relative"
        role="dialog"
        aria-modal="true">
        {/* Modal Header */}
        <div className="flex justify-end self-end items-center mb-4">
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-400 hover:text-gray-600">
            ✕
          </button>
        </div>

        <div className="w-9/12  self-center   p-2 flex flex-col items-center">
          <h1 className="text-lg font-gilroy-bold">
            Are you sure you want to {type} this applicant?
          </h1>
          <div className="flex justify-center items-center space-x-4  w-full mt-4">
            {
              <button
                onClick={() =>
                  handleApproval(id, type === "Approve" ? true : false)
                }
                className="w-3/12 bg-[#008080] text-white font-gilroy-bold p-2 rounded-lg transition duration-300 ease-in-out hover:scale-110">
                Yes
              </button>
            }
            {
              <button
                onClick={() => setIsOpen(false)}
                className="w-3/12 bg-red-600 text-white font-gilroy-bold p-2 rounded-lg transition duration-300 ease-in-out hover:scale-110">
                No
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
