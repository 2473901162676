import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { path } from "../../../path";

function Users() {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [allUsers, setAllUsers] = useState([]);
  const [searchtext, setSearchText] = useState("");
  /* eslint-disable no-warning-comments */
  // const [userFallback, setUserFallback] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Fetch users with debouncing to reduce API calls
  const getAllUsers = useCallback(async (page = 1, searchTerm = searchtext) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `${path}/admin/allUsers?page=${page}&limit=100&search=${searchTerm}`,
        config
      );

      setAllUsers(res.data.users);
      setTotalPages(res.data.totalPages);
      setTotalCount(res.data.totalCount);
      // setUserFallback(res.data.users);
    } catch (error) {
      console.error(error);
      alert("Some error in getting users.");
    }
  }, [searchtext, token]);

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
    getAllUsers();
  }, [dispatch, getAllUsers]);

  useEffect(() => {
    console.log("Fetching users for page: ", currentPage);
    getAllUsers(currentPage, searchtext);
  }, [currentPage, searchtext, getAllUsers]);

  const removeParticularAccount = async (userId, userEmail) => {
    try {
      const password = window.prompt("Enter password ");
      if (password) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.post(
          `${path}/matchpassword`,
          { userId: user._id, password },
          config
        );
        if (Object.keys(res.data).length === 2) {
          alert("Password matched successfully.");
          const deleteRes = await axios.post(
            `${path}/admin/deleteaccount`,
            { userId, userEmail },
            config
          );
          setAllUsers(allUsers.filter((us) => us._id !== userId));
          deleteRes.data.success === true
            ? alert("Account deleted successfully.")
            : alert("Error in deleting account.");
        } else {
          alert(res.data.msg);
        }
      } else {
        alert("Please enter a password first.");
        return;
      }
    } catch (error) {
      console.log(error);
      alert("Error in deleting account.");
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setCurrentPage(1); // Reset to first page on search change
    }, 500);
    setDebounceTimeout(timeout);
  };

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2 py-[3rem] overflow-x-none overflow-y-auto">
      <div className="grid grid-cols-3 items-center">
        <div className="border-2 shadow-xl m-2 rounded-full flex flex-col justify-center items-center py-4">
          <h1 className="font-bold">Total Users</h1>
          <p>{totalCount}</p>
        </div>
        <div>
          <input
            type="text"
            value={searchtext}
            onChange={handleSearchChange}
            placeholder="Search By Username"
            className="text-grayish search-tab-event p-4 rounded-3xl border m-1 border-viewMore w-[31vw] h-8 mb-2 bg-primary outline-none focus:ring-2 ring-[#008080] Searchbox"
          />
        </div>
      </div>
      <table className="border-collapse border-2 m-4">
        <thead className="border-b-2">
          <tr>
            <th className="border-r-2 px-2">S.No</th>
            <th className="border-r-2 px-2">User Id</th>
            <th className="border-r-2 px-2">Name</th>
            <th className="border-r-2 px-2">Username</th>
            <th className="border-r-2 px-2">Email Id</th>
            <th className="border-r-2 px-2">Gender</th>
            <th className="border-r-2 px-2">Phone Number</th>
            <th className="border-r-2 px-2">Payment Details</th>
            <th className="border-r-2 px-2">Joined on</th>
            <th>Remove Account</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((u, index) => (
            <tr key={u._id}>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{index + 1}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u._id}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.firstName} {u.lastName}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.username}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.email}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.gender}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.phone || "NULL"}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">{u.paymentDetail || "NULL"}</td>
              <td className="border-r-2 border-b-2 px-2 text-[0.5rem] text-center">{u?.createdAt?.slice(0, 10)}</td>
              <td className="text-[0.8rem] border-b-2 text-center">
                <button className="text-red-500" onClick={() => removeParticularAccount(u._id, u.email)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg">
        Previous
      </button>
      <button
        onClick={() => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))}
        disabled={currentPage === totalPages}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg">
        Next
      </button>
    </div>
  );
}

export default Users;
