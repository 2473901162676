import Login from "./Login";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import LogoDark from "./logo/dark_logo.png";

import Logo from "./logo/small_login.png";
import Footer from "../footer/Footer";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import classes from "./LandingPage.module.css";
import { SendHGoogleEvent } from "../../analytics/ga";

import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { path } from "../../path";
import AppleIcon from "@mui/icons-material/Apple";
import { getRandomUserName } from "./UsernameGenration";
import "./Login.css";
import webVideo from "./web_video.mp4";
import mobileVideo from "./mobile_video.mp4";
// const fs = require("fs");

// import AppleLoginBtn from "./SignUp/AppleLoginBtn";
// import AppleSignin from "react-apple-signin-auth";

// const Illustration = lazy(() => import("./logo/Logins.svg"));
const LandingPage = () => {
  const dispatch = useDispatch();


  const [theme] = useCookies(["Theme"]);

  const [openMobileLogin, setopenMobileLogin] = useState(false);
  const clientId = "feedants.com";
  const scope = "name email";
  const redirectURI = "https://www.feedants.com/";
  const state = "origin:web";
  // https://tbvpnc.csb.app/
  // https://1007-2409-40c4-1e-b592-59cb-799b-a826-b98b.ngrok-free.app/

  const history = useHistory();
  const handleOpenSignUp = () => {
    history.push("/signup");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const googleLogin = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read",
    onSuccess: async ({ access_token }) => {
      setIsLoading(true);
      try {
        // console.log(access_token);
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        const extraData = await axios.get(
          "https://people.googleapis.com/v1/people/me?personFields=birthdays,genders",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        await GoogleSignInFunc(userInfo, extraData);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
        setIsLoading(false);
        console.log(error);
      }
    },
    onFailure: () => {
      setError(true);
      setIsLoading(false);
    },
  });

  const AppleSignInFunc = async (event) => {
    if (event?.detail?.user) {
      const mailCheck = await checkEmail(event?.detail?.user?.email);
      if (mailCheck !== null) {
        await SetUser(mailCheck);
        SendHGoogleEvent("apple login", "User login Successfull");
        return;
      }

      const appleUser = {
        firstname: event?.detail?.user?.name?.firstName,
        lastname: event?.detail?.user?.name?.lastName,
        email: event?.detail?.user?.email,
      };

      await SignUp(appleUser, "apple");
      SendHGoogleEvent("apple signup", "User signup Successfull");
    } else {
      const email = jwtDecode(event?.detail?.authorization?.id_token).email;
      // console.log(email);
      const mailCheck = await checkEmail(email);
      // console.log(mailCheck);
      if (mailCheck !== null) {
        await SetUser(mailCheck);
        SendHGoogleEvent("apple login", "User login Successfull");
      }
    }
  };

  const GoogleSignInFunc = async (userInfo, extraData) => {
    const mailCheck = await checkEmail(userInfo?.data?.email);
    if (mailCheck) {
      SetUser(mailCheck);
      SendHGoogleEvent("google login", "User login Successfull");
      return;
    }

    const googleUser = {
      firstname: userInfo?.data?.given_name,
      lastname: userInfo?.data?.family_name,
      email: userInfo?.data?.email,
      gender: extraData?.data?.genders?.[0]?.value,
      birthday: extraData?.data?.birthdays?.[0]?.date,
    };

    await SignUp(googleUser, "google");
    SendHGoogleEvent("google signup", "User signup Successfull");
    return;
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.post(`${path}/checkemail`, {
        email,
      });

      if (response?.data?.success === true) {
        return response;
      }
      // console.log(response);
      return null;
    } catch (err) {
      console.log(err);
    }
  };

  // const AppleLoginFunc = (response) => {
  //   dispatch({ type: "SET_HEADER" });
  //   localStorage.setItem("myToken", response.data.token);
  //   const date = new Date();
  //   console.log("Date:", date.getMinutes());
  //   dispatch({
  //     type: "SET_USER",
  //     token: response.data.token,
  //     user: response.data.user,
  //     loginTime: date.getMinutes(),
  //   });
  //   SendHGoogleEvent("apple login", "User login Successfull");
  //   if (response.data.user?.skills.length >= 3) {
  //     history.push("/home");
  //   } else {
  //     history.push("/timeline");
  //   }
  // };

  useEffect(() => {
    window.AppleID?.auth.init({
      clientId,
      scope,
      redirectURI,
      state,
      usePopup: true,
    });

    document.addEventListener("AppleIDSignInOnSuccess", async (event) => {
      // Handle successful response.
      console.log("Success ", event);
      // const idToken = event?.detail?.authorization?.code;
      // const decodedToken = jwtDecode(idToken);
      // console.log(decodedToken);
      await AppleSignInFunc(event);
    });

    document.addEventListener("AppleIDSignInOnFailure", (event) => {
      console.log("Error ", event);
    });

    // return () => {
    //   document.removeEventListener("AppleIDSignInOnSuccess");
    //   document.removeEventListener("AppleIDSignInOnFailure");
    // };
  }, []);

  const SetUser = async (response) => {
    dispatch({ type: "SET_HEADER" });
    localStorage.setItem("myToken", response.data.token);
    const date = new Date();
    // console.log("Date:", response);
    dispatch({
      type: "SET_USER",
      token: response.data.token,
      user: response.data.user,
      loginTime: date.getMinutes(),
    });
    if (response.data.user?.skills.length >= 3) {
      history.push("/home");
    } else {
      history.push("/timeline");
    }
    // history.push("/timeline");
    //   SendHGoogleEvent("google login", "User login Successfull");
  };

  const SignUp = async (socialUser, type) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };

    try {
      let datee = null;
      if (
        socialUser?.birthday?.year !== undefined &&
        socialUser?.birthday?.month !== undefined &&
        socialUser?.birthday?.day !== undefined
      ) {
        datee = new Date();
        datee.setFullYear(socialUser?.birthday?.year);
        datee.setMonth(socialUser?.birthday?.month);
        datee.setDate(socialUser?.birthday?.day);
      }
      console.log(datee);
      const username = await getRandomUserName(
        socialUser?.firstname,
        socialUser?.lastname
      );
      // console.log(username);
      let response = null;
      if (type === "google") {
        response = await axios.post(
          `${path}/googlesignup`,
          {
            firstName: socialUser?.firstname,
            lastName: socialUser?.lastname,
            gender: socialUser?.gender,
            username: username,
            email: socialUser?.email,
            birthdate: datee,
          },
          config
        );
      } else {
        response = await axios.post(
          `${path}/applesignup`,
          {
            firstName: socialUser?.firstname,
            lastName: socialUser?.lastname,
            username: username,
            email: socialUser?.email,
          },
          config
        );
      }

      console.log(response);

      if (response?.data?.success === true) {
        await SetUser(response);
      } else if (response?.data?.success === false) {
        //   toast.error(response?.data?.msg, toastOption);
        console.log(response?.data?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const setUser = async (userInfo, extraData) => {
  //   const response = await axios.post(`${path}/checkemail`, {
  //     email: userInfo?.data?.email,
  //   });

  //   // console.log(response);
  //   if (response?.data?.success == true) {
  //     // console.log(response);
  //     dispatch({ type: "SET_HEADER" });
  //     localStorage.setItem("myToken", response.data.token);
  //     const date = new Date();
  //     console.log("Date:", date.getMinutes());
  //     dispatch({
  //       type: "SET_USER",
  //       token: response.data.token,
  //       user: response.data.user,
  //       loginTime: date.getMinutes(),
  //     });
  //     if (response.data.user?.skills.length >= 3) {
  //       history.push("/home");
  //     } else {
  //       history.push("/timeline");
  //     }
  //     // history.push("/timeline");
  //     SendHGoogleEvent("google login", "User login Successfull");
  //   } else {
  //     SetgoogleUser({
  //       firstname: userInfo?.data?.given_name,
  //       lastname: userInfo?.data?.family_name,
  //       email: userInfo?.data?.email,
  //       gender: extraData?.data?.genders?.[0]?.value,
  //       birthday: extraData?.data?.birthdays?.[0]?.date,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   console.log(googleUser);
  //   if (Object.keys(googleUser) != 0)
  //     history.push({ pathname: "/googlesignin", state: googleUser });
  // }, [googleUser]);

  // console.log(theme);
  useEffect(() => {
    console.log("poiuytrtyui");
    dispatch({ type: "RESET_OTP_VERIFIED" });
    dispatch({ type: "SET_EMAIL", payload: "" });
    dispatch({ type: "UNSET_OTP" });
    dispatch({ type: "UNSET_REGISTER_ERRORS" });
    dispatch({ type: "UNSET_NEW_USER" });
    dispatch({ type: "UNSET_NEW_SKILLS" });
    dispatch({ type: "UNSET_TEMP_N_SKILLS" });
  }, []);
  const year = new Date().getFullYear();

  return (
    <div className="h-[100vh]">
      {/* Adding React-Helmet for meta description on every Page See the title of each page */}

      <Helmet>
        <title>FeedAnts</title>
        <meta name="description" content="Learn skills while surfing" />
      </Helmet>

      <div
        className="bg-page z-10 text-primary hidden md:flex md:flex-col md:justify-around h-[100vh]"
        data-testid="landing-page">
        <div className="md:flex md:flex-row h-[100vh] md:justify-center md:items-center content-center pt-[3rem] mr-[2.25rem] md:pt-[0px]  md: space-x-[2.5rem] lg:space-x-[5rem] ">
          <div className=" hidden md:flex items-center justify-center rounded-md w-[650px] h-[600px]  z-10 flex-row justify-center pt-[3rem] lg:pt-[5rem] login_main_image_container ">
            {/* <img
              style={{ maxWidth: "500px" }}
              className=" max-w-[500px] rounded-lg login_main_image"
              data-testid="landing-img"
              src={theme.Theme == "dark" ? dark_bg : light_bg}
              alt=""
            /> */}
            <video
              src={webVideo}
              className="w-full h-full object-fill rounded-3xl"
              autoPlay
              loop={true}
              muted></video>
          </div>

          <div
            style={{ minHeight: "400px" }}
            className="flex-col z-20 justify-center items-center border border-solid border-viewMore dark:border-[#919eab] mt-[3rem] px-[0.5rem] lg:px-0 lg:pl-[1.25rem]   md:mt-[3rem] md:py-[1.5rem]  2xl:py-[2rem] shadow-border rounded-xl space-y-0  ">
            <div className="mb-[10px] items-center  mt-[1rem] landing_page_logo">
              <img
                className={
                  theme.Theme === "dark"
                    ? "md:scale-100 w-[60%] h-[7vh] lg:h-[8vh] lg:ml-0 ml-2"
                    : "md:scale-100 w-[60%] h-[7vh] lg:h-[8vh] lg:ml-0 ml-2"
                }
                src={theme.Theme === "dark" ? LogoDark : Logo}
                alt="FeedAnts"
              />
            </div>

            <div className="flex flex-col items-center justify-center lg:mr-5 xl:mr-2">
              <Login />
              <div className="flex-row">
                <div>
                  <span className="text-xs font-semibold text-secondary font-Open Sans md:text-sm">
                    New to FeedAnts?
                  </span>
                  <button
                    type="submit"
                    data-testid="create-acc"
                    className="font-Open Sans py-[0.1rem] px-1 text-[#008080] text-xs xl:text-sm   lg:py-[0.1rem] font-semibold transition duration-200 hover:text-[#006e6e]"
                    onClick={handleOpenSignUp}>
                    {" "}
                    Create New Account
                  </button>
                </div>
              </div>
            </div>

            <div className="xl:mr-4 lg:mr-5">
              <div className={`${classes.orLine} dark:text-white`}>
                <div
                  className={`${classes.line} ${theme.Theme === "dark" ? "bg-white" : "bg-black"
                    }`}></div>
                <div
                  className={`${classes.orText} dark:text-primary light:text-primary`}>
                  Or
                </div>
                <div
                  className={`${classes.line} ${theme.Theme === "dark" ? "bg-white" : "bg-black"
                    }`}></div>
              </div>
              <div>
                {!isLoading && <div className="text-center">Sign in With</div>}
                <div className="flex justify-center mt-2 gap-2">
                  {isLoading && <p>Signing in...</p>}
                  <button
                    className={classes.gsi_material_button}
                    onClick={() => {
                      googleLogin();
                    }}
                    disabled={isLoading}>
                    <div
                      className={classes.gsi_material_button_content_wrapper}>
                      {error && (
                        <div className={classes.gsi_material_button_icon}>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{ display: "block" }}>
                            <path
                              fill="#EA4335"
                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path
                              fill="#4285F4"
                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path
                              fill="#FBBC05"
                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path
                              fill="#34A853"
                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </svg>
                          {/* {btn} */}
                        </div>
                      )}
                      {!isLoading && !error && (
                        <div className={classes.gsi_material_button_icon}>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{ display: "block" }}>
                            {/* Your Google icon paths */}
                            <path
                              fill="#EA4335"
                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path
                              fill="#4285F4"
                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path
                              fill="#FBBC05"
                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path
                              fill="#34A853"
                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  </button>
                  <button
                    onClick={() => window.AppleID.auth.signIn()}
                    className="p-2 bg-white rounded-full border-black border flex text-center items-center justify-center">
                    <AppleIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-page pt-7 landing_privacy_link">
          <Footer />
        </div>
      </div>

      {/* Mobile view */}
      <div className="bg-page text-primary md:hidden flex flex-col justify-around items-center h-[100vh] ">
        {openMobileLogin ? (
          <div className="bg-page text-primary w-[87%] md:hidden">
            <div
              style={{ minHeight: "400px" }}
              className="bg-page text-primary flex-col justify-center items-center border border-solid border-viewMore  mt-[1rem] px-[0.5rem] lg:px-0 lg:pl-[1.25rem] shadow-border rounded-xl space-y-0  md:hidden">
              <div className="bg-page text-primary flex  justify-center items-center  mt-[1.25rem] md:hidden">
                <img
                  className={
                    theme.Theme === "dark"
                      ? "w-[60%] h-auto sm:w-[46%] sm:h-[9vh]"
                      : " w-[60%] h-auto sm:w-[46%] sm:h-[9vh]"
                  }
                  src={theme.Theme === "dark" ? LogoDark : Logo}
                  alt="FeedAnts"
                />
              </div>

              <div className="bg-page text-primary md:hidden">
                <Login />
                <div className="flex-row pb-2 ml-4">
                  <span className="text-xs dark:text-secondary  text-[#354064] font-Open Sans font-semibold">
                    New to FeedAnts?
                  </span>
                  <button
                    type="submit"
                    className="font-Open Sans py-[0.1rem] px-1 text-[#008080] text-xs xl:text-sm   lg:py-[0.1rem] font-semibold transition duration-200 hover:text-[#006e6e]"
                    onClick={handleOpenSignUp}>
                    {" "}
                    Create New Account
                  </button>
                </div>
              </div>
              <div className="xl:mr-4 lg:mr-5 mb-4">
                <div className={classes.orLineMobile}>
                  <div
                    className={`${classes.line} ${theme.Theme === "dark" ? "bg-white" : "bg-black"
                      }`}></div>
                  <div
                    className={`${classes.orText} dark:text-primary light:text-primary`}>
                    Or
                  </div>
                  <div
                    className={`${classes.line} ${theme.Theme === "dark" ? "bg-white" : "bg-black"
                      }`}></div>
                </div>
                <div className="text-center">Sign in With </div>
                <div className="flex justify-center mt-2 mb-3 gap-2">
                  <button
                    className={classes.gsi_material_button}
                    onClick={() => {
                      googleLogin();
                    }}>
                    <div
                      className={classes.gsi_material_button_content_wrapper}>
                      <div className={classes.gsi_material_button_icon}>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          style={{ display: "block" }}>
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => window.AppleID.auth.signIn()}
                    className="p-2 bg-white rounded-full border-black border flex text-center items-center justify-center">
                    <AppleIcon />
                  </button>
                  {/* <div
                    id="appleid-sign"
                    className="signin-button w-10 h-10"
                    data-color="white"
                    data-border="true"
                    data-mode="logo-only"
                    data-border-radius="50"
                    data-logo-size="small"
                    data-type="sign-in"></div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex relative flex-col p-2 items-center justify-between gap-2">
            <video
              src={mobileVideo}
              className="w-[90%] h-[85vh] object-fill rounded-3xl"
              autoPlay
              loop={true}
              muted></video>
            <button
              onClick={() => setopenMobileLogin(true)}
              className="p-2 z-10 mb-4 px-4 py-2 w-[80%] absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-teal-600 text-white hover:bg-teal-500 font-gilroy-semibold rounded-md">
              Login
            </button>
          </div>
        )}

        <div>
          <Link
            to={"/privacy-policy"}
            target={"_blank"}
            className="text-[#A6A6A6] text-sm text-grayish font-semibold w-[100%]">
            Privacy Policy | FeedAnts {year}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
