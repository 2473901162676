import axios from "axios";
import React, { useEffect, useState } from "react";
import { path } from "../../path";

import MediaModal from "./MediaModal";
import ListComponent from "./ListComponent";
import DetailComponent from "./DetailComponent";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { Link } from "react-router-dom/cjs/react-router-dom";

const JudgeApplicantLists = () => {
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [applicantData, setApplicantData] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [mediaSrc, setMediaSrc] = useState(null);
  const [statusFilter, setStatusFilter] = useState("pending"); // Added status filter for tabs
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState(false);

  const [id, setId] = useState(null);

  // Fetch applicants based on status
  const getApplicantsByStatus = async (status, page = 1) => {
    try {
      const res = await axios.get(`${path}/getJudgeApplicants`, {
        params: { page, limit: 10, status }, // Fetch applicants based on status
      });
      setList(res.data.judges || []);
      setTotalPages(res.data.totalPages || 0);
      setTotalCount(res.data.totalCount || 0);
      setCurrentPage(Number(res.data.currentPage) || 1);
    } catch (error) {
      console.error("Error fetching applicants:", error);
    }
  };

  // Handle tab change
  const handleTabChange = (status) => {
    setStatusFilter(status);
    getApplicantsByStatus(status, currentPage); // Fetch the list based on selected status
    setApplicantData(null); // Clear detailed view when tab changes
    setCurrentPage(1);
  };

  const getApplicantData = async (id, item) => {
    setApplicantData(item);
  };

  const handleApproval = async (id, approve) => {
    if (id?.length) {
      try {
        const res = await axios.put(`${path}/assign-judge`, {
          userId: id,
          status: approve,
        });
        console.log("response ", res);
        alert("Done");
        setConfirmationType(false);
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Id missing");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      getApplicantsByStatus(statusFilter, newPage); // Fetch new page data based on selected status
    }
    setApplicantData(null);
  };

  useEffect(() => {
    getApplicantsByStatus(statusFilter, currentPage); // Fetch applicants on component mount
  }, [statusFilter, currentPage]);

  const handleMedia = (type, mediaSrc, modalOpen) => {
    setMediaSrc(mediaSrc);
    setMediaType(type);
    setIsOpen(modalOpen);
  };

  const handleConfirmation = async (type, modalOpen, id) => {
    setConfirmationType(type);
    setOpenConfirmation(modalOpen);
    setId(id);
  };

  return (
    <section className="bg-white h-screen p-2">
      <div className="flex items-center space-x-20 px-2 py-2">
        <Link to={"/marketingAdmin"} className="">
          <div className="flex items-center ">
            <img
              src="https://w7.pngwing.com/pngs/848/762/png-transparent-computer-icons-home-house-home-angle-building-rectangle-thumbnail.png"
              className="h-6 mr-3"
              alt="home"
            />
            <h1>Home</h1>
          </div>
        </Link>
      </div>
      <p className="text-2xl mb-4 text-center self-center ml-16">
        Judge Applicantions
      </p>
      {/* Tab Navigation */}
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => handleTabChange("pending")}
          className={`p-2 rounded ${statusFilter === "pending" ? "bg-blue-500 text-white" : "bg-gray-200"}`}>
          Pending
        </button>
        <button
          onClick={() => handleTabChange("approved")}
          className={`p-2 rounded ${statusFilter === "approved" ? "bg-green-500 text-white" : "bg-gray-200"}`}>
          Approved
        </button>
        <button
          onClick={() => handleTabChange("rejected")}
          className={`p-2 rounded ${statusFilter === "rejected" ? "bg-red-500 text-white" : "bg-gray-200"}`}>
          Rejected
        </button>
      </div>

      {/* Media Modal */}
      {isOpen && (
        <MediaModal media={mediaSrc} type={mediaType} setIsOpen={setIsOpen} />
      )}

      {openConfirmation && (
        <ConfirmationModal
          setIsOpen={setOpenConfirmation}
          type={confirmationType}
          handleApproval={handleApproval}
          id={id}
        />
      )}

      <div className="flex items-start">
        {/* List of Applicants */}
        <ListComponent
          applicantData={applicantData}
          data={list}
          currentPage={currentPage}
          getApplicantData={getApplicantData}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
        {/* Detail Component */}
        {applicantData && (
          <DetailComponent
            applicantData={applicantData}
            setApplicantData={setApplicantData}
            handleMedia={handleMedia}
            handleApproval={handleApproval}
            handleConfirmation={handleConfirmation}
          />
        )}
      </div>
    </section>
  );
};

export default JudgeApplicantLists;
