import React from "react";
import { Avatar as Avt } from "@mui/material";
import logoAnt from "../Header/img/Ant.png";
function formatDate(inputDate) {
  const date = new Date(inputDate);

  const options = {
    day: "2-digit",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}
const DetailComponent = ({
  applicantData,
  setApplicantData,
  handleMedia,
  handleConfirmation,
}) => {
  const Avatar = React.memo(Avt);

  return (
    <div className="w-8/12  p-2 h-[580px] overflow-hidden overflow-y-scroll bg-gray-100 rounded-lg">
      <div className="flex items-center justify-between  mx-2">
        <div></div>
        <h1 className="text-xl my-2 font-gilroy-bold text-center self-center">
          Details of applicant
        </h1>

        <h1
          className="text-lg my-2  cursor-pointer"
          onClick={() => setApplicantData(null)}>
          X
        </h1>
      </div>
      <div className="flex  p-2">
        <div className="w-8/12  ">
          {[
            {
              label: "Name",
              value:
                applicantData?.user?.firstName +
                " " +
                applicantData?.user?.lastName,
            },
            { label: "ID ", value: applicantData?.user?._id },
            { label: "username ", value: applicantData?.user?.username },
            { label: "Email", value: applicantData?.user?.email },
            { label: "Phone", value: applicantData?.user?.phone },
            { label: "UPI", value: applicantData?.user?.paymentDetail },
            {
              label: "Apply Date",
              value: formatDate(applicantData?.createdAt),
            },
            {
              label: "Experience",
              value: applicantData?.experienceYears + " yrs",
            },
            {
              label: "Skill Category",
              value: applicantData?.skill_category?.join(", "),
            },
            {
              label: "Skill Sub-category",
              value: applicantData?.skill_subcategory?.join(", "),
            },
          ].map(({ label, value }) => (
            <div key={label} className="flex space-x-2 p-2">
              <h1 className="font-gilroy-semibold text-gray-500">{label}:</h1>
              <h1 className="font-gilroy-semibold">{value || "N/A"}</h1>
            </div>
          ))}
        </div>
        <div className="w-4/12  p-2 flex justify-center items-center">
          {applicantData?.user.profilePicture ? (
            <Avatar
              src={applicantData?.user.profilePicture}
              sx={{
                width: 200,
                height: 200,
              }}
            />
          ) : (
            <Avatar
              src={logoAnt}
              sx={{
                width: 200,
                height: 200,
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full  p-2">
        <h1 className="font-gilroy-semibold text-gray-500">
          Introduction Video
        </h1>
        <div className="flex w-48 sm:w-60 pr-6 transition mb-4 duration-300 ease-in-out hover:scale-110 hover:cursor-pointer">
          <video
            className="w-full aspect-video border "
            controls
            src={applicantData?.user.judgeVideo}></video>
        </div>

        <h1 className="font-gilroy-semibold text-gray-500">Achievements :</h1>
        <h1 className="font-gilroy-semibold">
          {applicantData?.achievements || "N/A"}
        </h1>
        <div className="space-y-2 p-2 bg-white rounded-lg ">
          <h1 className="font-gilroy-semibold text-gray-500">
            Degrees/Certificates
          </h1>
          <div className="mt-3 flex flex-wrap items-center ">
            {applicantData?.qualifications?.map((item) => (
              <div
                key={item?.file_id}
                onClick={() => handleMedia("image", item?.file_url, true)}
                className="flex w-48 sm:w-60 pr-6 transition mb-4 duration-300 ease-in-out hover:scale-110 hover:cursor-pointer">
                <img
                  alt="qualifications"
                  src={item.file_url}
                  className="max-h-[200px] border "
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full  p-2">
        <h1 className="font-gilroy-semibold text-gray-500">
          Featured work links :
        </h1>
        <div className="flex flex-col">
          {applicantData?.featured_work_links?.map((link) => (
            <a
              href={link}
              key={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline m-2">
              {link}
            </a>
          ))}
        </div>
        {/* Signature Videos */}
        <h1 className="font-gilroy-semibold text-gray-500">
          Signature Videos :
        </h1>

        <div className="mt-3 w-full flex flex-wrap items-center space-y-2 p-2 bg-white rounded-lg">
          {applicantData?.signature_videos?.map((item) => (
            <div
              key={item?.video_id}
              onClick={() => handleMedia("video", item?.video_url, true)}
              className="flex w-48 sm:w-60 pr-6 transition mb-4 duration-300 ease-in-out hover:scale-110 hover:cursor-pointer">
              {/* <div className="top-20">
                <PlayButton
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                />
              </div> */}
              <video
                className=" w-full aspect-video border "
                // controls
                src={item?.video_url}></video>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center items-center space-x-4">
        {applicantData?.status !== "approved" && (
          <button
            onClick={() =>
              handleConfirmation("Approve", true, applicantData?.user._id)
            }
            className="w-3/12 bg-[#008080] text-white font-gilroy-bold p-2 rounded-lg transition duration-300 ease-in-out hover:scale-110">
            Approve
          </button>
        )}
        {applicantData?.status !== "rejected" && (
          <button
            onClick={() =>
              handleConfirmation("Reject", true, applicantData?.user._id)
            }
            className="w-3/12 bg-red-600 text-white font-gilroy-bold p-2 rounded-lg transition duration-300 ease-in-out hover:scale-110">
            Reject
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailComponent;
