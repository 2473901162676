import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed


const PrivateRoutes = (props) => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Check if the token has expired
        if (decodedToken.exp < currentTime) {
          toast.error("Session expired. Please log in again.");
          dispatch({ type: "LOGOUT" });
        }
      } catch (error) {
        console.error(error);
        toast.error("Invalid token. Please log in again.");
        dispatch({ type: "LOGOUT" });
      }
    }
  }, [token, dispatch]);

  return user && token ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};

PrivateRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.func,
};

export default PrivateRoutes;
