import React from "react";
import { Avatar as Avt } from "@mui/material";
import logoAnt from "../Header/img/Ant.png";
// import { ReactComponent as PlayButton } from "../../img/PlayButton.svg";

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const options = {
    day: "2-digit",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}
const ListComponent = ({
  applicantData,
  data,
  currentPage,
  getApplicantData,
  handlePageChange,
  totalPages,
  totalCount,
}) => {
  const Avatar = React.memo(Avt);

  return (
    <div
      className={`${applicantData ? "w-4/12 " : "w-11/12"}  flex flex-col justify-center items-center p-2`}>
      {/* Box list  */}
      <div className="flex w-full bg-[#008080] justify-between text-white items-center border rounded-lg mx-5 p-2 cursor-pointer px-2">
        <div className="flex space-x-2 items-center   w-5/12 ">
          <h3>User</h3>
        </div>
        {!applicantData && (
          <div className="w-3/12  ">
            {/* skill */}
            <h3>Skill</h3>
          </div>
        )}

        {!applicantData && (
          <div className="w-2/12 ">
            {/* Experience */}
            <h3>Experience</h3>
          </div>
        )}
        {!applicantData && (
          <div className="w-2/12 ">
            {/* date */}
            <h3>Date</h3>
          </div>
        )}
      </div>

      {data?.length ? (
        data?.map((item, i) => (
          <div
            key={item?._id}
            onClick={() => getApplicantData(item._id, item)}
            className={`flex w-full  justify-between items-center ${item?._id === applicantData?._id ? "bg-gray-300" : "bg-white"} border my-2 rounded-lg mx-5 p-2 cursor-pointer px-2`}>
            <div className="flex space-x-2 items-center   w-5/12">
              {/* index */}
              <h3>{(currentPage - 1) * 3 + i + 1}</h3>
              {/* profile picture */}
              <div className=" rounded-full overflow-hidden   bg-purple-600 ">
                {item?.user?.profilePicture ? (
                  <Avatar
                    src={item?.user.profilePicture}
                    sx={{
                      width: 30,
                      height: 30,
                    }}
                  />
                ) : (
                  <Avatar
                    src={logoAnt}
                    sx={{
                      width: 30,
                      height: 30,
                    }}
                  />
                )}
              </div>
              {/* first name    */}
              <h3>
                {item?.user.firstName} {item?.user.lastName}
              </h3>
            </div>
            {!applicantData && (
              <div className=" w-3/12 ">
                {/* skill */}
                <h3>{item?.skill_category}</h3>
              </div>
            )}

            {!applicantData && (
              <div className="w-2/12 ">
                {/* Experience */}
                <h3>{item?.experienceYears}</h3>
              </div>
            )}
            {!applicantData && (
              <div className="w-2/12 ">
                {/* date */}
                <h3>{formatDate(item?.createdAt)}</h3>
              </div>
            )}
          </div>
        ))
      ) : (
        <div>No list</div>
      )}
      {/* Pagination Controls */}
      <div className={`fixed bottom-0 w-full mx-2 pl-3 mb-1 overflow-hidden  `}>
        <div
          className={`flex justify-between items-center self-center ${applicantData ? "w-4/12 " : "w-11/12"} mx-auto px-2`}>
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="px-2 py-2 bg-gray-200 rounded disabled:opacity-50">
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages} ({totalCount} total applicants)
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="px-2 py-2 bg-gray-200 rounded disabled:opacity-50">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListComponent;
