// local backend server

// export const path = "http://localhost:8080";
// export const path = "https://testants.azurewebsites.net";
// export const path = "http://192.168.225.235:5000";

// post sharing url
export const sharePostUrlPrefix = "https://www.feedants.com/#/";
// export const sharePostUrlPrefix = "https://testants2.azurewebsites.net/#"; // It is for testAnts

// production server don't use in it devlopment
export const path = "https://api.blaccsckull.com";
// our development and test server
// export const path = "https://backendbs.onrender.com"
